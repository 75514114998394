import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { flexRender, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table';
import { useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { getAnswerTypeResults } from '../../../../lib/api-client/answers/AnswersApi';
import { waitingForAtom } from '../AnswersPage.atoms';

function useGetAnswerResults(questionId: string, page: { page: number; size: number }) {
  return useQuery({
    queryKey: ['questions', questionId, 'answer', 'result-set', page],
    queryFn: () => getAnswerTypeResults(questionId, 'result-set', page),
    placeholderData: keepPreviousData,
  });
}

// eslint-disable-next-line import/prefer-default-export
export function AnswersTable({ questionId }: { questionId: string }) {
  const setWaitingFor = useSetAtom(waitingForAtom);
  const [inputValue, setInputValue] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetched } = useGetAnswerResults(questionId, {
    page: pagination.pageIndex,
    size: pagination.pageSize,
  });

  useEffect(() => {
    if (isFetched) {
      setWaitingFor('QUESTION');
    }
  }, [isFetched, setWaitingFor, questionId]);

  const defaultData = useMemo(() => [], []);

  const columns = useMemo(() => {
    if (!data?.metadata) {
      return [];
    }

    return data.metadata?.columns.map((metadata, index) => ({
      id: metadata.name,
      header: metadata.name,
      accessorFn: (row: any) => row[index],
    }));
  }, [data]);

  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: data?.totalPages,
    manualPagination: true,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  if (isLoading) {
    return (
      <Stack>
        <Skeleton w="full" h="5" />
        <Skeleton w="full" h="5" />
        <Skeleton w="full" h="5" />
      </Stack>
    );
  }

  function stripedBackground(idx: number) {
    return idx % 2 === 0 ? 'white' : 'gray.50';
  }

  return (
    <>
      <Table variant="explorer" minW="full" w={table.getTotalSize()}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row, idx) => (
            <Tr key={row.id} bg={stripedBackground(idx)}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <HStack
        justify="space-between"
        position="sticky"
        left="0"
        w="full"
        p="4"
        borderTop="1px solid"
        borderColor="gray.300"
      >
        {data && (
          <Text fontSize="xs" fontWeight="semibold" color="gray.600">
            Showing{' '}
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{' '}
            {(table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize} of{' '}
            {data.totalElements}
          </Text>
        )}
        <ButtonGroup variant="ghost">
          <IconButton
            aria-label="Go to page 1"
            icon={<Icon iconImage={IconImage.chevronBackBookend} />}
            onClick={() => {
              table.firstPage();
              setInputValue(1);
            }}
            isDisabled={!table.getCanPreviousPage()}
          />

          <IconButton
            aria-label="Go to previous page"
            icon={<Icon iconImage={IconImage.chevronBack} />}
            onClick={() => {
              table.previousPage();
              setInputValue(table.getState().pagination.pageIndex - 1);
            }}
            isDisabled={!table.getCanPreviousPage()}
          />
          <HStack>
            <span>Page</span>
            <HStack>
              <Input
                w="14"
                value={inputValue}
                type="number"
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setInputValue(parseInt(e.target.value ?? 0, 10));
                  }
                }}
              />
              <Button
                onClick={() => {
                  setPagination({
                    pageIndex: inputValue - 1,
                    pageSize: pagination.pageSize,
                  });
                }}
              >
                Go
              </Button>
            </HStack>
            <span>of {data?.totalPages ?? 0}</span>
          </HStack>
          <IconButton
            aria-label="Go to next page"
            icon={<Icon iconImage={IconImage.chevronForward} />}
            onClick={() => {
              table.nextPage();
              setInputValue(table.getState().pagination.pageIndex + 1);
            }}
            isDisabled={!table.getCanNextPage()}
          />
          <IconButton
            aria-label="Go to last page"
            icon={<Icon iconImage={IconImage.chevronForwardBookend} />}
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          />
        </ButtonGroup>
      </HStack>
    </>
  );
}
