import * as Sentry from '@sentry/react';
import { ChakraProvider } from '@chakra-ui/react';
import { CoreProvider } from '@hazae41/xswr';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import consola, { LogLevel } from 'consola';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { bootstrap } from './bootstrap';
import SystemErrorPage from './components/shared/errors/SystemErrorPage/SystemErrorPage';
import config from './config';
import { AuthenticationProvider } from './context/AuthenticationContext';
import { AuthorizationProvider } from './context/AuthorizationContext';
import './index.css';
import SentryReactRouterV6RouterInstrumentation from './lib/sentry/SentryReactRouterV6RouterInstrumentation/SentryReactRouterV6RouterInstrumentation';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme/theme';

consola.level = LogLevel.Verbose;
consola.wrapAll();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
    },
  },
});

function renderReactApp() {
  createRoot(document.getElementById('root') as HTMLElement).render(
    <Sentry.ErrorBoundary
      fallback={
        <ChakraProvider theme={theme}>
          <SystemErrorPage />
        </ChakraProvider>
      }
    >
      <React.StrictMode>
        <ChakraProvider theme={theme}>
          <FlagsmithProvider flagsmith={flagsmith}>
            <QueryClientProvider client={queryClient}>
              <CoreProvider
                timeout={config.get().XSWR_DEFAULT_TIMEOUT_MS}
                cooldown={1000}
                expiration={1000}
              >
                <AuthenticationProvider>
                  <AuthorizationProvider>
                    <BrowserRouter>
                      <App />
                      <SentryReactRouterV6RouterInstrumentation />
                    </BrowserRouter>
                  </AuthorizationProvider>
                </AuthenticationProvider>
              </CoreProvider>
            </QueryClientProvider>
          </FlagsmithProvider>
        </ChakraProvider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>
  );
}

config
  .load()
  .then(bootstrap)
  .then(() => {
    renderReactApp();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
