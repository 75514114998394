import { Box, Skeleton, Text } from '@chakra-ui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { lazy, Suspense } from 'react';
import { getAnswerTypeResults } from '../../../../lib/api-client/answers/AnswersApi';

const Plot = lazy(() => import('react-plotly.js'));

function useGetVisualizationAnswerResults(questionId: string) {
  return useQuery({
    queryKey: ['questions', questionId, 'answer', 'visualization'],
    queryFn: () => getAnswerTypeResults(questionId, 'visualization'),
    placeholderData: keepPreviousData,
  });
}

// eslint-disable-next-line import/prefer-default-export
export function VisualizationAnswerType({ questionId }: { questionId: string }) {
  const { data, isLoading } = useGetVisualizationAnswerResults(questionId);

  if (isLoading) {
    return <Skeleton h="4" w="full" />;
  }

  if (data == null) {
    return <Text fontSize="xl">No visualization could be loaded</Text>;
  }

  return (
    <Box w="full">
      <Suspense fallback={<Skeleton h="4" w="full" />}>
        <Plot
          data={data.payload.data}
          layout={{ ...data.payload.layout, width: undefined, height: undefined }}
          useResizeHandler
        />
      </Suspense>
    </Box>
  );
}
