import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra,
  Circle,
  Flex,
  FlexProps,
  HStack,
  Icon as ChakraIcon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFlags } from 'flagsmith/react';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import { Link, matchPath, resolvePath, To, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/harpinAI-logo-graphic-only.svg';
import { useAuth } from '../../../../context/AuthenticationContext';
import { Can } from '../../../../context/AuthorizationContext';
import { usePageHistory } from '../../../../context/PageHistoryContext';
import { useDataSources } from '../../../../lib/api-client/sources/SourceData';
import { useSourceIssues } from '../../../../lib/api-client/sources/SourceIssuesData';
import { SourceIssuePriority } from '../../../../lib/api-client/sources/model/SourceIssuePriority';
import HeadwayWidget from '../../../../lib/headway/HeadwayWidget/HeadwayWidget';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import { FeatureFlags } from '../../../../lib/model/FeatureFlags';
import { isUndefined } from '../../../../lib/utils/utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import DataSourceIcon from '../../../features/connections/DataSourceIcon/DataSourceIcon';

const MotionFlex = motion<FlexProps>(Flex);

export function SettingsHoverState() {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const onSignOut = () => {
    signOut().then(() => {
      navigate(`/signin`);
    });
  };

  return (
    <VStack
      bgColor="gray.800"
      alignItems="start"
      w="190px"
      h="120"
      p={2}
      spacing={-1}
      boxShadow="md"
      borderRadius="6px"
      pos="fixed"
      mb="130"
      ml="18px"
      zIndex="1000"
    >
      <Button as={Link} variant="ghost" textColor="white" to="/harpin-tools">
        harpin AI internal tools
      </Button>
      <Button as={Link} variant="ghost" textColor="white" to="/settings">
        Account settings
      </Button>
      <Button as={Link} variant="ghost" textColor="white" to="/security">
        Security & privacy
      </Button>
      <Button as={Link} variant="ghost" to="/sigin" textColor="white" onClick={onSignOut}>
        Sign out
      </Button>
    </VStack>
  );
}

export interface SystemHoverStateProps {
  label?: string;
}

export function ToolsHoverState({ label }: SystemHoverStateProps) {
  return (
    <VStack
      bgColor="gray.800"
      w="max-content"
      h="36px"
      p={2}
      spacing={-1}
      boxShadow="md"
      borderRadius="6px"
      pos="fixed"
      ml="40px"
      zIndex="2000"
      marginInlineStart={10}
    >
      <Button marginInlineStart={0} variant="ghost" textColor="white">
        {label}
      </Button>
    </VStack>
  );
}

interface SectionProps {
  iconOnly?: boolean;
}

interface SidenavDataItem {
  label: string;
  to?: To;
  icon?: JSX.Element;
  submenu?: SidenavDataItem[];
  matchPath?: string[] | false;
}

const tools: SidenavDataItem[] = [
  {
    label: 'Profile dashboard',
    icon: <Icon iconImage={IconImage.consumer360View} />,
    to: '/',
    matchPath: ['/', '/reports/*'],
  },
  {
    label: 'Explore all consumer profiles',
    icon: <Icon iconImage={IconImage.people} />,
    to: `/data-explorer/profile`,
  },
  {
    label: 'Find a consumer profile',
    icon: <Icon iconImage={IconImage.searchUser} />,
    to: '/profiles/search',
    matchPath: ['/profiles/*'],
  },
];

function SidenavItem({
  item,
  iconOnly = false,
  fontWeight = 'bold',
  children,
  activeVariant = 'solid',
}: {
  item: SidenavDataItem;
  fontWeight?: string;
  iconOnly?: boolean;
  activeVariant?: 'solid' | 'highlight';
  children?: ReactNode;
}) {
  let match = null;
  const location = useLocation();

  if (item.matchPath || (item.to && item.matchPath !== false)) {
    match = (item.matchPath ? item.matchPath : [item.to!]).reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      const resolved = resolvePath(curr);
      return !!matchPath(
        {
          path: resolved.pathname,
          end: true,
        },
        location.pathname
      );
    }, false);
  }

  if (item.submenu && iconOnly) {
    match = item.submenu.find((i) =>
      (i.matchPath ? i.matchPath : [i.to ?? '']).reduce((prev, curr) => {
        if (prev) {
          return prev;
        }

        const resolved = resolvePath(curr);
        return !!matchPath(
          {
            path: resolved.pathname,
            end: true,
          },
          location.pathname
        );
      }, false)
    );
  }

  let sx: any = {
    pl: item.icon ? 6 : '64px',
  };

  if (match) {
    if (activeVariant === 'solid') {
      sx = {
        bg: 'primaryDark',
        color: 'highlight',
        borderLeft: '8px solid',
        borderColor: 'highlight',
        pl: `${item.icon ? 16 : 56}px`,
      };
    } else {
      sx = {
        color: 'highlight',
      };
    }
  }

  const linkProps: any = {};
  if (item.to) {
    linkProps.as = Link;
    linkProps.to = item.to;
  }

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  if (item.submenu) {
    return (
      <AccordionItem border={0}>
        <AccordionButton
          p={0}
          pr={iconOnly ? 0 : 3}
          _disabled={{ color: 'white', pointerEvents: 'none' }}
        >
          <HStack
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            spacing={4}
            w="100%"
            py={2.5}
            fontSize="sm"
            fontWeight="bold"
            pl={item.icon ? 6 : '64px'}
            sx={sx}
            _hover={{ color: 'highlight' }}
          >
            <HStack spacing={-0}>
              {iconOnly && isHovering && (
                <Popover closeOnBlur={false} placement="left" isOpen={isHovering}>
                  <>
                    <PopoverTrigger>
                      <chakra.span onClick={() => isHovering} />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bgColor="gray.800"
                        alignItems="start"
                        borderColor="none"
                        w="190px"
                        p={2}
                        left={6}
                        boxShadow="md"
                        borderRadius="6px"
                      >
                        {item.submenu.map((it: any) => (
                          <Button
                            colorScheme="blue"
                            key={it.to}
                            as={Link}
                            variant="ghost"
                            textColor="white"
                            to={it.to}
                          >
                            {it.label}
                          </Button>
                        ))}
                      </PopoverContent>
                    </Portal>
                  </>
                </Popover>
              )}
              {item.icon}
            </HStack>
            {!iconOnly && (
              <chakra.span textAlign="start" noOfLines={1}>
                {item.label}
              </chakra.span>
            )}
          </HStack>
          {!iconOnly && <AccordionIcon />}
        </AccordionButton>
        <AccordionPanel p={0}>
          <Stack spacing={0}>
            {item.submenu.map((sub, index) => {
              let subMenuMatch = null;
              let key = sub.label;
              if (sub.to) {
                const resolvedPath = resolvePath(sub.to);
                subMenuMatch = matchPath(
                  {
                    path: resolvedPath.pathname,
                    end: true,
                  },
                  location.pathname
                );
                key = typeof sub.to === 'string' ? sub.to : resolvedPath.pathname;
              }
              let sxx: any;
              if (subMenuMatch) {
                sxx = {
                  bg: 'primaryDark',
                  color: 'highlight',
                  borderLeft: '8px solid',
                  borderColor: 'highlight',
                };
              }
              return (
                <HStack
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${key}-${index}`}
                  spacing={4}
                  w="100%"
                  py={2.5}
                  pl={subMenuMatch ? 14 : 16}
                  fontSize="sm"
                  fontWeight="normal"
                  color="white"
                  as={Link}
                  to={sub.to}
                  _hover={{ color: 'highlight' }}
                  {...sxx}
                >
                  {!iconOnly && <Text>{sub.label}</Text>}
                </HStack>
              );
            })}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    );
  }

  return (
    <HStack
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      spacing={4}
      w="100%"
      py={2.5}
      pr={5}
      fontSize="sm"
      fontWeight={fontWeight}
      color="white"
      sx={sx}
      {...linkProps}
      _hover={{ color: 'highlight' }}
    >
      {iconOnly && isHovering ? (
        <HStack marginInlineStart={-2}>
          {item && <ToolsHoverState label={item.label} />}
          <chakra.span>{item.icon}</chakra.span>
        </HStack>
      ) : (
        <chakra.span>{item.icon}</chakra.span>
      )}
      {!iconOnly && !children && (
        <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {item.label}
        </Text>
      )}
      {children}
    </HStack>
  );
}

function SourceSystemsSection({ iconOnly }: { iconOnly?: boolean }) {
  const { data: useDataSourcesData } = useDataSources();
  const sources = useDataSourcesData?.content;
  const [expandedIndexes, setExpandedIndexes] = useState<number[] | number>([]);

  const { data: sourceIssuesData }: any = useSourceIssues(SourceIssuePriority.High);
  const highPrioritySourceIds =
    sourceIssuesData?.content?.map((issue: any) => issue.sourceId) ?? [];
  const location = useLocation();

  useEffect(() => {
    const found = sources?.findIndex((source) => {
      const resolved = resolvePath(`/sources/${source.id}/*`);
      return !!matchPath(
        {
          path: resolved.pathname,
          end: true,
        },
        location.pathname
      );
    });

    if (!isUndefined(found)) {
      setExpandedIndexes((prev) => {
        const ei = typeof prev === 'number' ? [prev] : (prev as number[]);
        return Array.from(new Set([found, ...ei]));
      });
    }
  }, [location, sources]);

  return (
    <Stack spacing={2}>
      {!iconOnly && (
        <chakra.span fontSize="xs" pl={6} fontWeight="medium" color="whiteAlpha.700">
          Source Systems
        </chakra.span>
      )}

      <SidenavItem
        item={{
          label: 'Enterprise data dashboard',
          icon: <Icon iconImage={IconImage.health} />,
          to: '/sources',
        }}
        iconOnly={iconOnly}
      />
      <Accordion
        allowMultiple
        index={iconOnly ? [] : expandedIndexes}
        onChange={(ex) => {
          setExpandedIndexes(ex);
        }}
      >
        {sources
          ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map((source) => (
            <SidenavItem
              key={source.id}
              iconOnly={iconOnly}
              activeVariant="highlight"
              item={{
                label: source.name,
                to: '',
                matchPath: [`/sources/${source.id}/*`],
                icon: (
                  <Flex position="relative">
                    {highPrioritySourceIds.includes(source.id) && (
                      <Circle position="absolute" overflow="clip" ml="-6px" mt="-6px">
                        <Icon
                          iconImage={IconImage.error}
                          boxSize={4}
                          color="error"
                          bgColor="white"
                          bgClip="border-box"
                        />
                      </Circle>
                    )}
                    <DataSourceIcon boxSize="24px" sourceSystem={source?.sourceSystem} />
                  </Flex>
                ),
                submenu: [
                  {
                    label: 'Source data dashboard',
                    to: `/sources/${source.id}`,
                  },
                  {
                    label: 'Data record explorer',
                    to: `/sources/${source.id}/data-explorer`,
                  },
                ],
              }}
            />
          ))}
      </Accordion>
    </Stack>
  );
}

function ToolsSection({ iconOnly }: SectionProps) {
  return (
    <Stack spacing={2}>
      {!iconOnly && (
        <chakra.span fontSize="xs" pl={6} fontWeight="medium" color="whiteAlpha.700">
          Tools
        </chakra.span>
      )}
      {tools.map((tool) => (
        <Box key={`item-${tool.label}`}>
          <SidenavItem item={tool} iconOnly={iconOnly} />
          {tool.submenu &&
            tool.submenu.map((submenuItem) => (
              <SidenavItem
                key={`item-sub-${submenuItem.label}`}
                item={submenuItem}
                iconOnly={iconOnly}
              />
            ))}
        </Box>
      ))}
      <Can I="manage" a="SeviinAdmin">
        <SidenavItem
          iconOnly={iconOnly}
          item={{
            icon: <Icon iconImage={IconImage.robotAlt} />,
            label: 'Answers',
            to: '/answers',
          }}
        />
      </Can>
    </Stack>
  );
}

function FooterSection({ iconOnly }: SectionProps) {
  const navigate = useNavigate();
  const { signOut, tenantName } = useAuth();
  const onSignOut = () => {
    signOut().then(() => {
      navigate(`/signin`);
    });
  };

  const flags = useFlags([FeatureFlags.SHOW_RELEASE_NOTES]);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleClick = () => {
    onSignOut();
    Mixpanel.track('User Sign Out');
  };

  return (
    <Accordion
      position="sticky"
      bottom={0}
      allowToggle
      bg="primary"
      boxShadow="inset 0px 7px 3px -3px rgba(0, 0, 0, 0.2);"
    >
      <AccordionItem border={0} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <AccordionButton py="18px" _disabled={{ color: 'white', pointerEvents: 'none' }}>
          <HStack w="full" pl={2} spacing={4} overflow="auto">
            <HStack spacing={-0}>
              {iconOnly && isHovering && <SettingsHoverState />}
              <Icon iconImage={IconImage.settings} />
            </HStack>
            {!iconOnly && (
              <chakra.span fontSize="sm" fontWeight="bold" noOfLines={1}>
                {tenantName}
              </chakra.span>
            )}
          </HStack>
          {!iconOnly && <AccordionIcon />}
        </AccordionButton>
        <AccordionPanel px={0} pt={0} pb={4}>
          <Stack spacing={0}>
            <Can I="manage" a="SeviinAdmin">
              <SidenavItem
                iconOnly={iconOnly}
                item={{
                  label: 'harpin AI internal tools',
                  to: '/harpin-tools',
                  matchPath: ['/harpin-tools/*'],
                }}
                fontWeight="normal"
              />
            </Can>
            {flags.show_release_notes.enabled && (
              <Box pl={20} py={1} _hover={{ color: 'highlight', cursor: 'pointer' }}>
                <HeadwayWidget />
              </Box>
            )}
            <SidenavItem
              iconOnly={iconOnly}
              item={{
                label: 'Account settings',
                to: '/settings',
              }}
              fontWeight="normal"
            />
            <SidenavItem
              iconOnly={iconOnly}
              item={{
                label: 'Security & privacy',
                to: '/security',
              }}
              fontWeight="normal"
            />
            <HStack
              spacing="4"
              pt="2"
              pl="20"
              _hover={{ color: 'highlight', cursor: 'pointer' }}
              w="full"
              onClick={handleClick}
            >
              {!iconOnly && <Text fontSize="sm">Sign out</Text>}
            </HStack>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function HeaderSection({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) {
  return (
    <Flex pt={6} pb={8} pr={3} pl={6} alignItems="center" justifyContent="space-between">
      {isOpen && (
        <HStack>
          <Text fontSize="md" fontWeight="bold" lineHeight="6" textColor="white">
            harpin AI
          </Text>
          <ChakraIcon boxSize="5">
            <Logo />
          </ChakraIcon>
        </HStack>
      )}
      <IconButton
        aria-label="m"
        bgColor="transparent"
        padding={0}
        height="full"
        minW={6}
        icon={
          isOpen ? (
            <Icon iconImage={IconImage.accordionLeft} />
          ) : (
            <Icon iconImage={IconImage.accordionRight} />
          )
        }
        onClick={() =>
          isOpen
            ? [onClose(), Mixpanel.track('Navigation Bar Collapse')]
            : [onOpen(), Mixpanel.track('Navigation Bar Expand')]
        }
        _hover={{ bg: 'none' }}
      />
    </Flex>
  );
}

export default function Sidenav() {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { history } = usePageHistory();

  let width = '320px';
  if (!isOpen) {
    width = '80px';
  }

  return (
    <MotionFlex
      animate={{ minWidth: width }}
      pos="relative"
      flexDir="column"
      h="100vh"
      left="0"
      top="0"
      minW={width}
      w={width}
      maxW={width}
      bg="primary"
      overflowY="auto"
    >
      <Flex flexDir="column" h="full" justifyContent="space-between" color="white">
        <Box>
          <HeaderSection isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          <Stack spacing={2}>
            <ToolsSection iconOnly={!isOpen} />
            <SourceSystemsSection iconOnly={!isOpen} />
            {isOpen && (
              <chakra.span fontSize="xs" pl={6} fontWeight="medium" color="whiteAlpha.700">
                Connection management
              </chakra.span>
            )}
            <SidenavItem
              item={{
                label: 'Source connections dashboard',
                icon: <Icon iconImage={IconImage.databases} />,
                to: '/sources/connections',
              }}
              iconOnly={!isOpen}
            />
            {history.length > 0 && (
              <Stack spacing={2} pb={20}>
                {isOpen && (
                  <chakra.span fontSize="xs" pl={6} fontWeight="medium" color="whiteAlpha.700">
                    Recent work
                  </chakra.span>
                )}
                {history.map((h) => (
                  <SidenavItem
                    key={`sidenav-history-${h.location.key}`}
                    iconOnly={!isOpen}
                    item={{
                      label: h.label.label,
                      icon: h.label.icon,
                      to: h.location,
                      matchPath: false,
                    }}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Box>
        <FooterSection iconOnly={!isOpen} />
      </Flex>
    </MotionFlex>
  );
}
