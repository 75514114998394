import { Skeleton, Text } from '@chakra-ui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getAnswerTypeResults } from '../../../../lib/api-client/answers/AnswersApi';

function useGetTextAnswerResults(questionId: string) {
  return useQuery({
    queryKey: ['questions', questionId, 'answer', 'text'],
    queryFn: () => getAnswerTypeResults(questionId, 'text'),
    placeholderData: keepPreviousData,
  });
}

// eslint-disable-next-line import/prefer-default-export
export function TextAnswerType({ questionId }: { questionId: string }) {
  const { data, isLoading } = useGetTextAnswerResults(questionId);

  if (isLoading) {
    return <Skeleton h="4" w="full" />;
  }

  return <Text fontSize="xl">{data?.text}</Text>;
}
