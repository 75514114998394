import ApiClient from '../ApiClient';
import {
  ResultSetAnswer,
  QuestionResponse,
  SubmitNewQuestionRequest,
  AnswerType,
  TextAnswer,
  VisualizationAnswer,
  SaveFeedbackRequest,
  Feedback,
} from './answers.model';

export async function submitNewQuestion(req: SubmitNewQuestionRequest) {
  return ApiClient.post<QuestionResponse>(`/questions`, req).then((res) => res.data);
}

export async function getQuestion(questionId: string) {
  return ApiClient.get<QuestionResponse>(`/questions/${questionId}`).then((res) => res.data);
}

export async function generateAnswer(questionId: string) {
  return ApiClient.post<void>(`/questions/${questionId}/answer`).then(() => {});
}

export async function getAnswerResults(
  questionId: string,
  { page = 0, size = 10 }: { page?: number; size?: number } = {}
) {
  return ApiClient.get<ResultSetAnswer>(`/questions/${questionId}/answer`, {
    params: {
      page,
      size,
      includeMetadata: true,
    },
  }).then((res) => res.data);
}

type AnswerMapping = {
  text: TextAnswer;
  visualization: VisualizationAnswer;
  'result-set': ResultSetAnswer;
};

export async function getAnswerTypeResults<T extends AnswerType>(
  questionId: string,
  type: T,
  { page = 0, size = 10 }: { page?: number; size?: number } = {}
) {
  return ApiClient.get<AnswerMapping[T]>(`/questions/${questionId}/answer/${type}`, {
    params: {
      page,
      size,
      includeMetadata: true,
    },
  }).then((res) => res.data);
}

export async function saveFeedback(questionId: string, feedback: SaveFeedbackRequest) {
  return ApiClient.put<Feedback>(`/questions/${questionId}/feedback`, feedback).then(
    (res) => res.data
  );
}
