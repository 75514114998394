import { Box, Button, Stack, HStack, Textarea, Alert, AlertIcon } from '@chakra-ui/react';
import { useState } from 'react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

interface FeedbackModuleProps {
  onSubmit?: (feedback: { helpful: boolean; comment?: string }) => Promise<void>;
}

// eslint-disable-next-line import/prefer-default-export
export function FeedbackModule({ onSubmit }: FeedbackModuleProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [helpful, setHelpful] = useState<boolean | null>(null);
  const [comment, setComment] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (helpful !== null && onSubmit) {
      setIsSubmitting(true);
      await onSubmit({ helpful, comment });
      setShowSuccess(true);
      // Hide success message after 3 seconds
      setTimeout(() => setShowSuccess(false), 3000);
      setIsSubmitting(false);
    }
    setIsOpen(false);
    // Reset state
    setHelpful(null);
    setComment('');
  };

  if (showSuccess) {
    return (
      <Alert status="success" variant="subtle" borderRadius="md">
        <AlertIcon />
        Your feedback has been submitted
      </Alert>
    );
  }

  if (!isOpen) {
    return (
      <Box alignSelf="start">
        <Button size="xs" variant="ghost" onClick={() => setIsOpen(true)}>
          Feedback
        </Button>
      </Box>
    );
  }

  return (
    <Stack
      p={4}
      spacing={4}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      bg="white"
      maxW="400px"
    >
      <Box fontWeight="medium">Feedback</Box>

      <HStack spacing={4}>
        <Button
          leftIcon={<Icon iconImage={IconImage.thumbsUp} />}
          variant={helpful === true ? 'solid' : 'outline'}
          onClick={() => setHelpful(true)}
        >
          Helpful
        </Button>
        <Button
          leftIcon={<Icon iconImage={IconImage.thumbsDown} />}
          variant={helpful === false ? 'solid' : 'outline'}
          onClick={() => setHelpful(false)}
        >
          Not Helpful
        </Button>
      </HStack>

      <Textarea
        placeholder="Additional comments (optional)"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />

      <HStack justify="flex-end" spacing={4}>
        <Button
          variant="ghost"
          onClick={() => setIsOpen(false)}
          autoFocus
          isDisabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} isDisabled={helpful === null} isLoading={isSubmitting}>
          Submit Feedback
        </Button>
      </HStack>
    </Stack>
  );
}
