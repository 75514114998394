import { ISODateString } from '../../model/common';
import { Page } from '../../model/common/Page';

export type AnswerState =
  | 'ANSWER_PENDING'
  | 'ANSWER_IN_PROGRESS'
  | 'ANSWER_ERROR'
  | 'ANSWER_READY'
  | 'ANSWER_ABORTED';

export type QuestionState = 'QUESTION_IN_PROGRESS' | 'QUESTION_ERROR' | 'QUESTION_READY';

export type AnswerType = 'text' | 'visualization' | 'result-set';

export interface SubmitNewQuestionRequest {
  threadId?: string;
  question: string;
}

export interface QuestionResponse {
  questionId: string;
  threadId: string;
  question: string;
  answerTypes: AnswerType[];
  errorMessage?: string;
  questionState: QuestionState;
  answerState: AnswerState;
  interpretedQuestion?: string;
  generatedSql?: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
}

export function isTerminalQuestionState(questionState?: QuestionState) {
  return questionState != null && ['QUESTION_ERROR', 'QUESTION_READY'].includes(questionState);
}

export function isTerminalAnswerState(answerState?: AnswerState) {
  return (
    answerState != null && ['ANSWER_ABORTED', 'ANSWER_ERROR', 'ANSWER_READY'].includes(answerState)
  );
}

type AnswerResultContent = any[];

export interface ResultSetAnswer extends Page<AnswerResultContent> {
  metadata?: {
    columns: {
      name: string;
      dataType: 'STRING' | 'INTEGER' | 'FLOAT' | 'BOOLEAN' | 'TIMESTAMP'; // FIXME what data types are available
    }[];
  };
}

export interface TextAnswer {
  text: string;
}

export interface VisualizationAnswer {
  visualizationType: 'plotly';
  payload: { data: any; layout: any };
}

export interface SaveFeedbackRequest {
  score: number;
  comment?: string;
}

export interface Feedback {
  questionId: string;
  score: number;
  comment?: string;
  timestamp: ISODateString;
}
