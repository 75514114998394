import { getSchema, QueryParams } from '@hazae41/xswr';
import axios, { AxiosRequestConfig } from 'axios';
import { Config } from '../../config/config';

const instance = axios.create({
  paramsSerializer: {
    indexes: null,
  },
});

export function addInterceptor(
  onFulfilled?: (value: any) => any | Promise<any>,
  onRejected?: (error: any) => any
): number {
  return instance.interceptors.request.use(onFulfilled, onRejected);
}

export function setBaseUrl(baseUrl: string) {
  instance.defaults.baseURL = baseUrl;
}

export function setApiClientConfig(config: Config) {
  instance.defaults.baseURL = config.BASE_API_URL;
  instance.defaults.timeout = config.XSWR_MAX_TIMEOUT_MS;
}

export function setAuthorizationToken(token: string) {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

/**
 * Request transformation to disable authentication on a one off basis.
 *
 * Lifted from https://stackoverflow.com/questions/48819885/axios-transformrequest-how-to-alter-json-payload
 *
 * Usage:
 *    ApiClient.post<Pick<Page<TenantInfo>, 'content'>>('/tenant-info',
 *       {
 *         emailAddress,
 *       },
 *       {
 *         transformRequest: disableAuthentication(),
 *       }
 *     )
 */
export function disableAuthentication() {
  let defaultTransformations = axios.defaults.transformRequest || [];
  if (!Array.isArray(defaultTransformations)) {
    defaultTransformations = [defaultTransformations];
  }

  return [
    (data: any, headers?: any) => {
      if (headers) {
        if (headers.common) {
          // eslint-disable-next-line no-param-reassign
          delete headers.common.Authorization;
        }

        // eslint-disable-next-line no-param-reassign
        delete headers.Authorization;
      }
      return data;
    },
    ...defaultTransformations,
  ];
}

export function getApiClientSchema<T>(key: AxiosRequestConfig, enabled = true, timeout?: number) {
  let params: QueryParams<T> | undefined;
  if (timeout != null) {
    params = {
      timeout,
    };
  }
  return getSchema<T, AxiosRequestConfig>(enabled ? key : undefined, instance, params);
}

export function downloadData(data: any, filename: string) {
  const href = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export default instance;
