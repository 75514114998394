import {
  Box,
  Button,
  chakra,
  Code,
  HStack,
  Icon as ChakraIcon,
  Skeleton,
  Stack,
  Text,
  VStack,
  useToast,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'sql-formatter';
import * as yup from 'yup';
import { InferType } from 'yup';
import { ReactComponent as Logo } from '../../../assets/harpinAI-logo-graphic-only.svg';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import { AutoResizeTextarea } from '../../../components/core/form/AutoResizeTextarea/AutoResizeTextarea';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import {
  generateAnswer,
  getQuestion,
  submitNewQuestion,
  saveFeedback,
} from '../../../lib/api-client/answers/AnswersApi';
import {
  AnswerType,
  isTerminalAnswerState,
  isTerminalQuestionState,
  QuestionResponse,
} from '../../../lib/api-client/answers/answers.model';
import { waitingForAtom } from './AnswersPage.atoms';
import { AnswersTable } from './components/AnswersTable';
import { FeedbackModule } from './components/FeedbackModule';
import { TextAnswerType } from './components/TextAnswerType';
import { VisualizationAnswerType } from './components/VisualizationAnswerType';

const currentQuestionHistoryAtom = atom<
  {
    text: string;
    type: 'QUESTION' | 'ANSWER';
    questionId?: string;
    sql?: string;
    showAnswer?: boolean;
    answerTypes: AnswerType[];
    errorMessage?: string;
  }[]
>([]);

const isMostRecentHistoryAtom = atom((get) => (currentIndex: number) => {
  const history = get(currentQuestionHistoryAtom);
  return currentIndex === history.length - 1;
});

const focusOnTextAreaAtom = atom((get) => {
  const history = get(currentQuestionHistoryAtom);
  return !history[history.length - 1]?.sql;
});

const currentQuestionAtom = atom<QuestionResponse>();

const isLoadingAtom = atom<boolean>((get) => {
  const currentQuestion = get(currentQuestionAtom);
  const waitingFor = get(waitingForAtom);

  if (currentQuestion == null) {
    return false;
  }

  if (waitingFor === 'QUESTION') {
    return !isTerminalQuestionState(currentQuestion.questionState);
  }

  if (waitingFor === 'ANSWER') {
    return !isTerminalAnswerState(currentQuestion.answerState);
  }

  return false;
});

const autoExecuteQueryAtom = atom<boolean>(true);

function usePollingGetQuestion() {
  const question = useAtomValue(currentQuestionAtom);
  const waitingFor = useAtomValue(waitingForAtom);
  const questionId = question?.questionId ?? '';

  return useQuery({
    enabled: !!questionId,
    queryKey: ['questions', questionId],
    queryFn: () => getQuestion(questionId),
    refetchInterval: (query) => {
      const { data } = query.state;
      if (!data) return 1000;

      const isWaitingForQuestion =
        waitingFor === 'QUESTION' && !isTerminalQuestionState(data.questionState);
      const isWaitingForAnswer =
        waitingFor === 'ANSWER' && !isTerminalAnswerState(data.answerState);

      return isWaitingForQuestion || isWaitingForAnswer ? 1000 : undefined;
    },
  });
}

function useStartQuestionPolling() {
  const waitingFor = useAtomValue(waitingForAtom);
  const [currentQuestion, setCurrentQuestion] = useAtom(currentQuestionAtom);
  const setCurrentQuestionHistory = useSetAtom(currentQuestionHistoryAtom);

  const { data } = usePollingGetQuestion();

  useEffect(() => {
    if (data != null && data !== currentQuestion) {
      if (waitingFor === 'QUESTION' && isTerminalQuestionState(data.questionState)) {
        let text = data.interpretedQuestion ?? '';
        if (data.questionState === 'QUESTION_ERROR') {
          text = 'There was a problem processing your question. Please try again.';
        }
        setCurrentQuestionHistory((prev) => [
          ...prev,
          {
            text,
            sql: data.generatedSql,
            errorMessage: data.errorMessage,
            showAnswer: data.answerState === 'ANSWER_READY',
            type: 'ANSWER',
            questionId: data.questionId,
            answerTypes: data.answerTypes,
          },
        ]);
      } else if (waitingFor === 'ANSWER' && isTerminalAnswerState(data.answerState)) {
        setCurrentQuestionHistory((prev) => [
          ...prev,
          {
            text: 'Here are your results',
            showAnswer: true,
            type: 'ANSWER',
            questionId: data.questionId,
            answerTypes: data.answerTypes,
          },
        ]);
      }

      setCurrentQuestion(data);
    }
  }, [data, setCurrentQuestion, setCurrentQuestionHistory, waitingFor, currentQuestion]);
}

const answersFormSchema = yup.object({
  question: yup.string().required(),
  threadId: yup.string(),
});

type AnswersForm = InferType<typeof answersFormSchema>;

function AnswerSkeleton() {
  return (
    <Stack
      p="2"
      w="80%"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="md"
      alignSelf="start"
    >
      <HStack w="full">
        <ChakraIcon boxSize="5">
          <Logo />
        </ChakraIcon>
        <Skeleton w="full" h="5" />
      </HStack>
    </Stack>
  );
}

function AnswerContainer({
  index,
  text,
  sql,
  type,
  questionId,
  showAnswer = false,
  answerTypes = [],
  errorMessage,
}: {
  index: number;
  text: string;
  type: 'QUESTION' | 'ANSWER';
  questionId?: string;
  sql?: string;
  showAnswer?: boolean;
  answerTypes?: AnswerType[];
  errorMessage?: string;
}) {
  const toast = useToast();
  const setWaitingFor = useSetAtom(waitingForAtom);
  const isLoading = useAtomValue(isLoadingAtom);
  const isMostRecentHistory = useAtomValue(isMostRecentHistoryAtom);
  const autoExecuteQuery = useAtomValue(autoExecuteQueryAtom);

  const submitGenerateAnswer = useCallback(async () => {
    if (questionId) {
      await generateAnswer(questionId);
      setWaitingFor('ANSWER');
    }
  }, [questionId, setWaitingFor]);

  const isMostRecent = isMostRecentHistory(index);
  const isSubmitDisabled = isLoading || !isMostRecent;
  const useMessageAnswerAsMessage =
    !text && !!questionId && showAnswer && answerTypes.includes('text');

  let message = <Text fontSize="xl">{text}</Text>;
  if (useMessageAnswerAsMessage) {
    message = <TextAnswerType questionId={questionId} />;
  }

  useEffect(() => {
    if (autoExecuteQuery && sql && isMostRecent && !isLoading) {
      submitGenerateAnswer();
    }
  }, [autoExecuteQuery, sql, isMostRecent, isLoading, submitGenerateAnswer]);

  if (autoExecuteQuery && sql && type === 'ANSWER') {
    return null;
  }

  return (
    <Stack alignSelf={type === 'QUESTION' ? 'end' : 'start'} maxW="80%">
      <Stack
        p="2"
        bg={type === 'QUESTION' ? 'transparent' : 'gray.100'}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
      >
        <HStack w="full">
          {type === 'QUESTION' ? (
            <Icon iconImage={IconImage.user} />
          ) : (
            <ChakraIcon boxSize="5">
              <Logo />
            </ChakraIcon>
          )}
          {message}
        </HStack>
        {errorMessage && (
          <Code
            w="full"
            bgColor="gray.50"
            p="2"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            whiteSpace="pre-wrap"
          >
            {errorMessage}
          </Code>
        )}
        {sql && (
          <>
            <Code
              w="full"
              bgColor="gray.50"
              p="2"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              whiteSpace="pre-wrap"
            >
              {format(sql)}
            </Code>
            <Text fontSize="xl">Would you like to kick off query?</Text>
            <Box bgColor="gray.50" p="2">
              <Button onClick={submitGenerateAnswer} isDisabled={isSubmitDisabled}>
                Run query
              </Button>
            </Box>
          </>
        )}
        {showAnswer &&
          answerTypes.filter(
            (t) => !useMessageAnswerAsMessage || (useMessageAnswerAsMessage && t !== 'text')
          ).length > 0 &&
          questionId && (
            <Stack
              w="full"
              bgColor="gray.50"
              maxW="full"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              overflow="hidden"
            >
              {!useMessageAnswerAsMessage && answerTypes.includes('text') && (
                <Box p="4">
                  <TextAnswerType questionId={questionId} />
                </Box>
              )}
              {answerTypes.includes('visualization') && (
                <Box w="full">
                  <VisualizationAnswerType questionId={questionId} />
                </Box>
              )}
              {answerTypes.includes('result-set') && (
                <Box
                  w="full"
                  bgColor="gray.50"
                  maxW="full"
                  overflowX="auto"
                  overflowY="hidden"
                  borderTop="1px solid"
                  borderColor="gray.300"
                >
                  <AnswersTable questionId={questionId} />
                </Box>
              )}
            </Stack>
          )}
      </Stack>
      {type === 'ANSWER' && index > -1 && (
        <FeedbackModule
          onSubmit={async (feedback) => {
            try {
              await saveFeedback(questionId!, {
                score: feedback.helpful ? 1 : 0,
                comment: feedback.comment,
              });
            } catch (error) {
              toast({
                title: 'Unable to save feedback',
                description: 'Please try again later',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
            }
          }}
        />
      )}
    </Stack>
  );
}

export default function AnswersPage() {
  const [, setCurrentQuestion] = useAtom(currentQuestionAtom);
  const [currentChatHistory, setCurrentChatHistory] = useAtom(currentQuestionHistoryAtom);
  const isLoading = useAtomValue(isLoadingAtom);
  const focusOnTextArea = useAtomValue(focusOnTextAreaAtom);
  const ref = useRef<HTMLButtonElement>(null);
  const [autoExecuteQuery, setAutoExecuteQuery] = useAtom(autoExecuteQueryAtom);
  useStartQuestionPolling();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
    setFocus,
  } = useForm<AnswersForm>({
    resolver: yupResolver(answersFormSchema),
    mode: 'onBlur',
    defaultValues: {},
  });

  useEffect(() => {
    if (focusOnTextArea && !isLoading) {
      setFocus('question');
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [focusOnTextArea, setFocus, isLoading]);

  const onSubmit = async (data: AnswersForm) => {
    setCurrentChatHistory((prev) => [
      ...prev,
      { text: data.question, type: 'QUESTION', answerTypes: [] },
    ]);
    const questionResponse = await submitNewQuestion(data);
    setCurrentQuestion(questionResponse);
    if (data.threadId == null) {
      setValue('threadId', questionResponse.threadId);
    }
    setValue('question', '');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && !event.ctrlKey && event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget?.form?.requestSubmit();
    }
  };

  const showLoading = isLoading || isSubmitting;

  return (
    <PageLayout
      pageViewEvent={{ page: 'Answers chat' }}
      header={<Header title="Answers" icon={<Icon iconImage={IconImage.robotAlt} />} />}
    >
      <VStack spacing="4">
        <AnswerContainer
          index={-1}
          text={
            "Hi there! We're here to help you better understand your data. Ask a question, we'll" +
            ' deliver an answer.'
          }
          type="ANSWER"
        />
        {currentChatHistory.map((history, idx) => (
          <AnswerContainer
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            index={idx}
            questionId={history.questionId}
            type={history.type}
            text={history.text}
            sql={history.sql}
            showAnswer={history.showAnswer}
            answerTypes={history.answerTypes}
            errorMessage={history.errorMessage}
          />
        ))}
        {showLoading && <AnswerSkeleton />}
        <chakra.form onSubmit={handleSubmit(onSubmit)} w="full">
          <Stack spacing={4} w="full">
            <HStack alignItems="start">
              <AutoResizeTextarea
                placeholder="Enter your message"
                onKeyDown={handleKeyDown}
                autoFocus={focusOnTextArea}
                {...register('question')}
              />
              <Button ref={ref} type="submit" maxW="32" w="full" mt="1" isDisabled={showLoading}>
                Submit
              </Button>
            </HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="auto-execute" mb="0">
                Automatically execute queries
              </FormLabel>
              <Switch
                id="auto-execute"
                isChecked={autoExecuteQuery}
                onChange={(e) => setAutoExecuteQuery(e.target.checked)}
              />
            </FormControl>
          </Stack>
        </chakra.form>
      </VStack>
    </PageLayout>
  );
}
