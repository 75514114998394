import { forwardRef, Textarea } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';

/**
 * Borrowed from https://github.com/chakra-ui/chakra-ui/issues/670#issuecomment-669916624
 */

// eslint-disable-next-line import/prefer-default-export
export const AutoResizeTextarea = forwardRef((props, ref) => (
  <Textarea
    minH="unset"
    overflow="hidden"
    w="100%"
    resize="none"
    ref={ref}
    minRows={1}
    transition="height none"
    as={ResizeTextarea}
    {...props}
  />
));
